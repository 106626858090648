import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import {QRCodeSVG} from 'qrcode.react';

const TicketsComponent = (props) => {

    var avulso = [];

    return(

        <section className="card-ticket-validados">
            {props.varTickets?.map((item) => {
                avulso.push(item.n_ticket)
               
                return(
                    <article key={item.n_ticket}>
                        <Card sx={{ maxWidth: 335, maxHeight: 300 }}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        Placa: {item.placa}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Entrada: {item.dt_entrada.slice(8,10) +'/' +  item.dt_entrada.slice(5,7) + '/' + item.dt_entrada.slice(0,4) + ' ' + item.dt_entrada.slice(11,19)}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Tolerância: {item.nova_tolerancia !== undefined ? item.nova_tolerancia.slice(8,10) +'/' +  item.nova_tolerancia.slice(5,7) + '/' + item.nova_tolerancia.slice(0,4) + ' ' + item.nova_tolerancia.slice(11,19) : ' '}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Validado por: {item.usuario}
                                    </Typography>

                                    <div className="GeraqrCode">
                                        <QRCodeSVG value={item.n_ticket} size="85" />
                                    </div>

                                    <div className="numeroTicket">
                                        <Typography gutterBottom variant="h6" component="div">
                                            {item.n_ticket}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        <br/>
                    </article>
                )
            })}
        </section>

    );
};

export default TicketsComponent;