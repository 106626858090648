import './footerButtons.css'

import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

const btnEnter = {
      backgroundColor:'#1976d2',
    "&:hover": {
    backgroundColor: '#1565c0'
    }
  };

  const btnClose = {
    backgroundColor:'#8e8f8d',
    "&:hover": {
      backgroundColor: '#8f8d8db3'
    }
  };

const FooterButtons = (props) => {
    return (
        <div className="buttons">
            <Button sx={btnEnter} variant="contained" type="submit" size="medium" onClick={props.onValidaTicket} color="success" spacing={2} startIcon={<CheckCircleOutlineIcon />}>Validar</Button>
            <br/>
            <Button sx={btnClose} variant="contained" type="button" size="medium" onClick={props.onAbreModal} startIcon={<CloseIcon />}>Close</Button>
        </div>
    );
};

export default FooterButtons;