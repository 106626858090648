import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ToleranciaComponent = (props) => {
    return(
        <div>
            <Typography variant="body2" color="text.primary">
                            Tolerância Adicionada
                        </Typography>

            <br/>
            <Box className="box-tol-gerada">
                <Typography variant="h4" className="box-tol-gerada-font">
                    {props.varNovaTolerancia}
                </Typography>
            </Box>
        </div>
    );
}

export default ToleranciaComponent;