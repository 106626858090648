import TextField from '@mui/material/TextField';

const InputShowersComponent = (props) => {
    return(

        <div>

            <TextField
                id="outlined-read-only-input"
                label="Entrada"
                type="text"
                margin="normal"
                defaultValue=" "
                value={props.varFrmtDataEnt}
                InputProps={{
                    readOnly: true,
                }}
                size="small"
                fullWidth
                disabled
            />

            <TextField
                id="outlined-read-only-input"
                label="Tolerância"
                type="text"
                margin="normal"
                defaultValue=" "
                value={props.varFrmtDataTole}
                InputProps={{
                    readOnly: true,
                }}
                size="small"
                fullWidth
                disabled
            />

        </div>

    );
};

export default InputShowersComponent;