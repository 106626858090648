import logo from '../../assets/logo.png'
import Typography from '@mui/material/Typography';

const LogoHeaderComponent = () => {
    return(
        <section>
            <div className="mt-3 mt-md-5">
                <div className="text-center">
                    <div>
                        <br/>
                        <figure>
                            <img src={logo} alt="Logo do sistema #1Park" className="img-logo" height="85" width="250"></img>
                        </figure>
                    </div>
                    <Typography className="mt-3" component="h5">
                        Validador de Tickets
                    </Typography>
                </div>
            </div>
        </section>
    );
};

export default LogoHeaderComponent;