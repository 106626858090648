import TextField from '@mui/material/TextField';

const InputPlacaComponent = (props) => {

    const placa = props.varPlaca

    return(

    <TextField 
        error={placa.length === 0 || placa === "" || placa.length > 8 || placa === " "}
        id="outlined-error-helper-text"
        label="Placa" 
        helperText={placa === undefined || placa === "" || placa.lenght === 0 || placa.length > 8 ? '*Placa inválida' : " "}
        required
        margin="normal"
        variant="outlined"
        size="small"
        onChange={props.onAddplaca}
        max={8}
        fullWidth
    />

    );
};

export default InputPlacaComponent;