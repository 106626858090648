import { QrReader } from 'react-qr-reader';

const ScannerQrcodeComponent = (props) => {

    const constraints = {
        facingMode: "environment"
    };

    return(
        <div>
            <article className="text-center">
                {props.varData !== "" && <h4>{props.varData}</h4>}
            </article>
                {props.varStartScan && (
                    <div>
                        <QrReader
                            constraints={constraints}
                            onResult={(result, error) => {
                            if (!!result) {
                                props.onSetterData(result?.text);
                                props.onSearchTicket(result?.text)
                            }

                            if (!!error) {
                                console.info(error);
                                }
                            }}
                            scanDelay={500}
                            style={{ width: '25%' }}
                        />
                    </div>
            )}
        </div>
    );
};

export default ScannerQrcodeComponent;