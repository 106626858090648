import React, {useState, useEffect, createContext} from "react";
import { useNavigate } from "react-router-dom";

import {api, createSession} from "../services/api";

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    
    const [loading,setLoading] = useState(true);
    const [user,setUser] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token')

        if(token){
            setUser(true)
            api.defaults.headers.Authorization = `Token ${token}`
        }

        setLoading(false);

    }, []);

    const login =  async (username,password) => {

        let response = ''
        try {
            response = await createSession(username,password)
        } catch(error){
            response = error.response
        }

        if(response.status === 200){
            const token = response.data.token
            localStorage.setItem("token",token)
            api.defaults.headers.Authorization = `Token ${token}`
            setUser(true)
            navigate("/");
        } 
         
    };

    const logout = () => {
        console.log("logout");
        localStorage.removeItem('token');
        api.defaults.headers.Authorization = null;
        navigate("/login")
    };

    return (
        <AuthContext.Provider
            value={{authenticated: user,loading, login, logout}}>
                {children}
        </AuthContext.Provider>
    )
};

