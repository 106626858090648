import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    
} from 'reactstrap';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { QrCode } from "@mui/icons-material";

//API IMPORTS
import { putValidaTickets, getTickets, getPatios } from '../../services/api';

//COMPONENTS IMPORT
import InputShowersComponent from './inputShowers';
import InputPlacaComponent from './inputPlaca';
import SliderComponent from './slider';
import ToleranciaComponent from './tolerancia';
import FooterButtons from './footerButtons';

const ModalComponent = (props) => {

    const ticket = props.varTicket
    const [minutos, setMinutos] = useState(0);
    const [horas, setHoras] = useState(0);
    const [dias, setDias] = useState(0);
    const [ano, setAno] = useState(true);
    const [placa, setPlaca] = useState([]);

    const [statusBotao, setStatusBotao] = useState(false);
    const [NovaTolerancia, setNovaTolerancia] = useState([])

    const [frmtDataTole, setfrmtDataTole] = useState()
    const [frmtDataEnt, setfrmtDataEnt] = useState()
    const [dateTolerancia,setDateTolerancia] = useState()

    const addPlaca = (e) =>{
        setPlaca(e.target.value)
    };

    const addHoras = (e) =>{
        setHoras(e.target.value)
    };

    const addDias = (e) => {
        setDias(e.target.value)
    };

    const abreModal = () =>{
        props.onSetterModal(!props.varModal);
        props.onSetterLoading(false);
        setHoras(0);
        setDias(0);
        setPlaca('');
    };

    const loadApiTickets = async () =>{
        props.onSetterLoading(true)
        const response = await getTickets()
        props.onSetterTickets(response.data)
        props.onSetterLoading(false)
     
    };

    const loadApiPatios = async () =>{
        props.onSetterLoading(true)
        const response = await getPatios()
        props.onSetterTickets(response.data.results)
        props.onSetterLoading(false)
      
    };

    const convertDateBr = (date) =>{
        let newDate = date.slice(8,10) + '/' +  date.slice(5,7) + '/' + date.slice(0,4) + ' ' + date.slice(11,19)
        return newDate
    }

    const convertToNewDate = (datahora) => {
        let day = datahora.slice(0,2)
        let mounth = datahora.slice(3,5)
        let year = datahora.slice(6,10)

        let hour = datahora.slice(11,13)
        let min = datahora.slice(14,16)
        let sec = datahora.slice(17,19)

        let newDate = `${year}-${mounth}-${day}T${hour}:${min}:${sec}`
        return newDate
    }

    const convertToNewDateT = (datahora) => {
        let day = datahora.slice(0,2)
        let mounth = datahora.slice(3,5)
        let year = datahora.slice(6,8)

        let hour = datahora.slice(9,11)
        let min = datahora.slice(13,15)
        let sec = datahora.slice(17,19)
        
        let newDate = `20${year}-${mounth}-${day}T${hour}:${min}:0${sec}-03:00`
        return newDate
    }


    const convertToToleDate = (DataAddAno,DataAddDias,numberInt) => {
        DataAddAno.setYear(DataAddDias.getFullYear() + parseInt(numberInt));        //Adiciona + 1 ano
        let ConvData = DataAddAno.toLocaleString().substring(0,19)
        let NovaData = ConvData.slice(0,5) + '/' + ConvData.slice(8,10) + '' + ConvData.slice(11,14) + '' +  ConvData.slice(14,17) + '' + ConvData.slice(17,19)
        return NovaData
    }



    const validaTicket = async () => {
        props.onSetterLoading(true)
        if(horas > 0 || dias > 0){
            if(placa.length === 7 || placa.length === 8){
                const dataTicket = {
                    n_ticket: ticket['n_ticket'],
                    tp_ticket: ticket['tp_ticket'],
                    placa: placa,
                    dt_entrada: ticket['dt_entrada'],
                    tolerancia: ticket['tolerancia'],
                    add_min: 0,
                    add_hora: horas,
                    add_dia: dias,
                    indeterminado: false,
                    nova_tolerancia: dateTolerancia,
                    id_patio: null,
                    usuario: ticket['usuario'],
                    status: ticket['status']
                };
                
             
                let numTicket = ticket['n_ticket']

                let response = ''
                try{
                     response = await putValidaTickets(numTicket,dataTicket)
                } 
                
                catch(error){
                    response = error.response
                }

                if(response.status === 200){
                    if(response.data.Error){
                        toast.error(response.data.Error)
                    }else{
                        toast.success(`Ticket ${numTicket} validado com sucesso!!`)
                        props.onSetterModal(!Modal)
                        props.onSetterTicket([''])
                        props.onSetterData('');
                        setPlaca('')
                        loadApiTickets()
                        //loadApiPatios()
                        props.onSetterLoading(true)
                        props.onSetterScan(false)
                        QrCode.close() 
                    }
                }else{
                    toast.info('OPS! Verifique se esse ticket existe!')
                };
                   
                   
            }else{
                toast.info('OPS! Digite a placa do veiculo corretamente')
            }

        }else{
            toast.info('OPS! Digite uma tolêrancia para validar o ticket!')
        }
    };


    useEffect(()=> {

        if(ticket.tolerancia !== undefined || ticket.dt_entrada !== undefined){
            let entrada = convertDateBr(ticket.dt_entrada)
      
            if(ticket.tolerancia){
                let tolerancia = convertDateBr(ticket.tolerancia)
                setfrmtDataTole(tolerancia)
            }else{
                setfrmtDataTole(entrada)
            }
     
            setfrmtDataEnt(entrada)

        }
    },[ticket.tolerancia, ticket.dt_entrada]);

    useEffect(()=> {
        const atualizaTolerancia = () =>{
            if(frmtDataTole !== undefined){
                let hora = convertToNewDate(frmtDataTole)
                let date = Date.parse(hora);
                let time = new Date(date);

                
             
                let DataAddMinutos = time
                let DataAddHoras = DataAddMinutos
                let DataAddDias = DataAddHoras
                let DataAddAno = DataAddDias
                
                DataAddMinutos.setMinutes(time.getMinutes() + parseInt(minutos));   //Adiciona + minutos
                DataAddHoras.setHours(DataAddMinutos.getHours() + parseInt(horas)); //Adiciona + horas
                DataAddDias.setDate(DataAddHoras.getDate() + parseInt(dias));       //Adiciona + dias
                
                if(statusBotao === true && ano === false){
                    
                    let NovaData = convertToToleDate(DataAddAno,DataAddDias,1)
                    setNovaTolerancia(NovaData)

                    let datahoraTolerancia = convertToNewDateT(NovaData)
                    setDateTolerancia(datahoraTolerancia)
                }
                if(ano === true && statusBotao === true){
                    
                    let NovaData = convertToToleDate(DataAddAno,DataAddDias,0)
                    setNovaTolerancia(NovaData)

                    let datahoraTolerancia = convertToNewDateT(NovaData)
                    setDateTolerancia(datahoraTolerancia)
        
                }else{
                   
                    let NovaData = convertToToleDate(DataAddAno,DataAddDias,0)
                    setNovaTolerancia(NovaData)

                    let datahoraTolerancia = convertToNewDateT(NovaData)
                    setDateTolerancia(datahoraTolerancia)

                }
            }
        }
        atualizaTolerancia();
    }, [minutos, horas, dias, ano, statusBotao]);

    return(
        <section className="modal-ticket">
            <Modal isOpen={props.varModal} modalTransition={{ timeout: 300 }}>
                <ModalHeader>

                </ModalHeader>

                <ModalBody>

                    <article>
                        <Typography className="mt-2 text-center" component="h6" variant="h6">
                            TICKET: {ticket.n_ticket}
                        </Typography>
                    </article>
                   

                    <form onSubmit={validaTicket}>

                        <InputShowersComponent 
                            varFrmtDataEnt={frmtDataEnt}
                            varFrmtDataTole={frmtDataTole}
                        />

                        <InputPlacaComponent 
                            varPlaca={placa}
                            onAddplaca={addPlaca}
                        />

                        <SliderComponent 
                            onAddHoras={addHoras}
                            onAddDias={addDias}
                        />

                        <ToleranciaComponent 
                            varNovaTolerancia={NovaTolerancia}
                        />

                        <br/>
                        
                    </form>

                </ModalBody>
                <ModalFooter>
                    <FooterButtons
                        onValidaTicket={validaTicket}
                        onAbreModal={abreModal}
                    />
                </ModalFooter>
            </Modal>
        </section>
    );
};

export default ModalComponent;