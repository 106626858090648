import React, {useState, useEffect} from "react";

import { toast } from 'react-toastify';

/* 
HABILITAR HTTPS

set HTTPS=true&&npm start

*/
import '../../app.css';


//API IMPORTS
import { api, getTickets, getTicketsNumber, getPatios } from "../../services/api";

//COMPONENTS IMPORTS
import ToastComponent from "../../components/ToastComponent"
import InputSearch from "../../components/InputSearch";
import QrCodeComponent from "../../components/QrCode/QrcodeScan";
import LoadingComponent from "../../components/loading";
import ModalComponent from "../../components/modal/modal";
import PatioComponent from "../../components/patio/patio";
import TicketsComponent from "../../components/tickets/tickets";
import HeaderMenuComponent from "../headerMenu";


const MenuComponent = () =>  
    
    {
        const tokenAPI = localStorage.getItem('token')
        if(tokenAPI){
            api.defaults.headers.Authorization = `Token ${tokenAPI}`
        }

        let [loading, setLoading] = useState(false);
        const [tickets, setTickets] = useState([]);
        const [patio, setPatio] = useState([]);
        const [modal, setModal] = useState(false);
        const [input, setInput] = useState([]);
        const [ticket, setTicket] = useState([]);
        
        const [startScan, setStartScan] = useState(false);
        const [data, setData] = useState("");

        const pesquisaTicketLeitor = (data) =>{
            let ticket = data
            if(ticket > 0 && ticket.length === 12){
                buscaTicketApi(ticket)
            }
        };

        useEffect(()=> {
            if((input) > 0 && (input).length === 12){
                buscaTicketApi(input)
            }
        },[input]);
    
        const pesquisaTicket = (e) =>{
            setInput(e.target.value)
        };

        const buscaTicketApi = async (ticket) => {
            setLoading(true)
            if(ticket.length >= 12){

                let response = ''
                try{
                    response = await getTicketsNumber(ticket)
                } catch(error){
                    response  =  error.response
                }
                

                if(response.status !== 200){
                    toast.info('OPS! Ticket não encontrado!')
                    setLoading(false)
                }else{
                    setTicket(response.data)
                    setLoading(false)
                    setModal(true)
              
                }
            
              
            };
        }

        const loadApiTickets = async () =>{
            setLoading(true)
            const response = await getTickets()
            setTickets(response.data)
            setLoading(false)
         
        };

        const loadApiPatios = async () =>{
            setLoading(true)
            const response = await getPatios()
            setPatio(response.data.results)
            setLoading(false)
          
        };

       
        /* Chamada das APIs */
        useEffect(()=> {
            loadApiTickets();
            loadApiPatios();
        },[]);

        return(
            <div>
                <main className="col-12 col-md-12 container">
                
                <ToastComponent/>

                <br/>
                <br/>
                <HeaderMenuComponent/>
                <InputSearch OnSearch={pesquisaTicket}
                    varInput={input}
                />
               
                <QrCodeComponent 
                    varStartScan={startScan}
                    onSetterScan={setStartScan}
                    varData={data}
                    onSetterData={setData}
                    onSearchTicket={pesquisaTicketLeitor}
                />

                <LoadingComponent varLoading={loading}/>
                
                <br/> 
                <ModalComponent 
                    onSetterLoading={setLoading}
                    onSetterData={setData}
                    onSetterTickets={setTickets}
                    onSetterScan={setStartScan}
                    onSetterModal={setModal}
                    onSetterTicket={setTicket}
                    varTicket={ticket}
                    varModal={modal}

                />
                <PatioComponent varPatio={patio}/>
                <TicketsComponent varTickets={tickets}/>  
                </main>
            </div>
        )
};
export default MenuComponent;