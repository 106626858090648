import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const SliderComponent = (props) => {
    return(

        <Box width={300}>
            <Typography variant="body2" color="text.primary">+ Horas</Typography>
            <Slider onChange={props.onAddHoras}
                aria-label="horas"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={0}
                max={24}
            />

            <Typography variant="body2" color="text.primary">+ Dias</Typography>
            <Slider onChange={props.onAddDias}
                aria-label="dias"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={0}
                max={20}
            />
        </Box>
    );
}

export default SliderComponent;