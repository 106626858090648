import TextField from '@mui/material/TextField';

const InputsLoginComponent = (props) => {
    return(
       <div>
            <TextField 
                margin="normal" 
                required
                fullWidth
                label="Usuário" 
                name="username"
                type="text"
                
                error={props.varError}
                value={props.varUsername} 
                onChange={props.onChangeUser}
            />

            <TextField 
                margin="normal" 
                required
                fullWidth
                label="Senha" 
                name="senha"
                type="password"
                helperText={props.varError === true ? '*Usúario ou senha incorretos' : ''} 
                error={props.varError}
                value={props.varPassword} 
                onChange={props.onChangePass}
                />
       </div>  
    );
};

export default InputsLoginComponent;