import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import './patio.css'

const PatioComponent = (props) => {

    var avulso = [];

    return(
        <section className="qtd-veiculo-patio">
            {props.varPatio.map((item) => {
                if (avulso.lenght < 0){
                    avulso = [0]
                }
                    return(
                        <article key={item.id_patio} className="progressbar col-12 col-md-12">
                            <Box sx={{ width: '95%' }}>

                                <Typography gutterBottom variant="h6" component="div">
                                    {item.nome_patio}
                                </Typography>
                                
                                <LinearProgress variant="determinate"  color="success" value={Math.round((item.total_utilizada * 100) / item.total_vaga)}/>
                                <br/>

                                <Typography variant="body2" color="text.secondary">
                                    Total de vagas: {item.total_vaga} | Disponiveis: {item.total_vaga - item.total_utilizada}
                                </Typography>

                                <Typography variant="body2" color="text.secondary">
                                    Utilizadas: {parseInt(item.total_utilizada)}
                                </Typography>

                                <Typography variant="body2" color="text.secondary">
                                    Credenciados: ****
                                </Typography> 
                            </Box>
                        </article>
                    )
            })}
            <br/>
        </section>
    );
};
export default PatioComponent;