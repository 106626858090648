import { useContext, useState } from 'react';

import Container from '@mui/material/Container';
import LogoHeaderComponent from './logoHeader';
import InputsLoginComponent from './inputs';
import ButtonsLoginComponent from './buttons';
import FooterLoginComponent from './footer';

import { AuthContext } from '../../contexts/auth';

const LoginComponent = () => {

    const { login } = useContext(AuthContext);
    

    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')
    const [error,setError] = useState(false)

    const changeUserHandler = (e) => {
        setUsername(e.target.value)
    }

    const changePassowrdHandler = (e) => {
        setPassword(e.target.value)
    }

    const submitHandler =  (e) => {
        e.preventDefault()
        const response =  login(username,password)
        console.log(response)
        if(response !== true){
            setError(true)
        }

    }

    return(
        <div>
            <main>
                <Container component="main" maxWidth="xs">

                    <LogoHeaderComponent/>
                    <section>
                        <form onSubmit={submitHandler}>
                            <div className="mt-4">
                                <InputsLoginComponent 
                                    varUsername={username}
                                    varPassword={password}
                                    onChangeUser={changeUserHandler}
                                    onChangePass={changePassowrdHandler}
                                    varError={error}
                                />
                                <br/>
                                <br/>
                                <ButtonsLoginComponent/>
                            </div>
                        </form>
                    </section>
                    <FooterLoginComponent/>
                    
                    
                </Container>
                
            </main>
        </div>
    )
       
};

export default LoginComponent;