import Button from '@mui/material/Button';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

const BtnQrcodeComponent = (props) => {

    const boxSX = {
        backgroundColor:'#5c6063',
        "&:hover": {
          backgroundColor: '#5c6063b5'
        }
      };
      

    return(

        <Button
            startIcon={<CameraAltOutlinedIcon/>}
            type="submit" 
            variant="contained" 
            color="primary"
            fullWidth
            size="medium" 
            className="mb-3 mb-md-4 md-4"
            onClick={() => { props.onSetterScan(!props.varStartScan) }}
            sx={boxSX}
        >
            {props.varStartScan ? "Fechar" : "SCANNER"}
        </Button>
        
    );
}

export default BtnQrcodeComponent;