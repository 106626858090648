import BtnQrcodeComponent from './btnQrcode';
import ScannerQrcodeComponent from './scannerQrcode';

const QrCodeComponent = (props) => {

    return (
        <section className="QrCode">

                    <BtnQrcodeComponent 
                        onSetterScan={props.onSetterScan}
                        varStartScan={props.varStartScan}
                    />
                    <ScannerQrcodeComponent
                        onSetterData={props.onSetterData}
                        onSearchTicket={props.onSearchTicket} 
                        varData={props.varData}
                        varStartScan={props.varStartScan}

                    />
        </section>
    );
};

export default QrCodeComponent;