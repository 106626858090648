import Container from '@mui/material/Container';
import ClipLoader from "react-spinners/ClipLoader";

const LoadingComponent = (props) => {
    return (
        <section className="spinner-loading">
            <Container component="main" maxWidth="xs">
                <div className="mt-3 mt-md-5">
                    <div className="text-center">
                    <ClipLoader className="col-12 col-md-12" loading={props.varLoading} size={20} align-content={"center"} />
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default LoadingComponent;
