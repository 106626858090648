import TextField from '@mui/material/TextField';

const InputSearch = (props) => {
    return (
        <section className="input-pesquisa">
            <TextField  className="col-12 col-md-12" 
                margin="normal"
                id="standard-basic"
                type="number" 
                label="Nº do ticket"
                variant="filled"
                onChange={props.OnSearch}
                value={props.varInput}
            />
        </section>
    );
};

export default InputSearch;

